.navbarContainer {
  padding: 1rem !important;
  border-bottom: 1px solid black;
  /* background-color: var(--color-white); */
  background-color: var(--color-navbar);
  backdrop-filter: blur(8px);
}

.links {
  margin-right: 2rem;
  font-weight: 700;
  color: var(--color-black) !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links.active:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  bottom: 10px;
  left: 0;
  background-color: var(--color-green);
  transition: transform 0.25s ease-out;
  transform-origin: bottom right;
  border-radius: 10rem;
}

.links:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  bottom: 10px;
  left: 0;
  background-color: var(--color-green);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  border-radius: 10rem;
}

.links:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.logo {
  width: 10rem;
}

.navbarToggle {
  border: none;
  padding: 0;
  animation: 1s navbar-animation-2;
}

.navbarToggle:focus {
  outline: none;
  box-shadow: none;
}

.toggleIcon {
  width: 2rem;
  /* text-align: right; */
}

.toggleIconLine {
  width: 100%;
  margin: 0.5rem 0;
  height: 3px;
  background-color: var(--color-black);
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 10rem;
}

.toggleIconLine:last-child {
  margin-left: auto;
  margin-right: 0;
}

.rotateLeft {
  transform: rotate(135deg);
  margin: 0 0 -3px 0;
}

.rotateRight {
  width: 100% !important;
  transform: rotate(45deg);
  margin: 0;
}

@media screen and (max-width: 991px) {
  .navbarContainer {
    text-align: center;
  }
  .links {
    margin-right: 0rem;
  }
  .links.active:before {
    background-color: transparent;
  }

  .links:after {
    background-color: transparent;
  }
  .links:hover:after {
    background-color: transparent;
  }
}
