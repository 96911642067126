.formSection {
  background-color: var(--color-white);
  height: 100%;
  text-align: center;
  padding: 3rem 0 1.5rem 0;
  text-align: center;
}

.formSection h2 {
  color: var(--color-black);
}

.formWrapper {
  max-width: 60rem;
  margin: 0 auto;
}

.mapContainer {
  width: 100%;
  height: 50vh;
}

.formInput:focus {
  border: 1px solid var(--color-black);
  box-shadow: none !important;
  font-weight: bold;
}

.serviceCard {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-color: var(--color-white);
  border-radius: 2.5rem;
  margin: 3rem 0;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

.serviceCardReversed {
  flex-direction: row-reverse;
}

.serviceCard:hover {
  transform: scale(1.01);
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.textWrapperReversed {
  align-items: flex-end;
}

.textWrapperReversed p {
  text-align: right;
}

.textWrapper h3 {
  color: var(--color-black);
}

.imageWrapper img {
  width: 15rem;
  max-width: 15rem;
  min-width: 15rem;
  border-radius: 2rem;
}

@media (max-width: 992px) {
  .textWrapper h3 {
    font-size: 1.5rem;
  }

  .textWrapper p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) { 
  .serviceCard {
    flex-direction: column-reverse;
  }

  .textWrapper {
    text-align: center;
  }

  .textWrapperReversed {
    align-items: center;
  }

  .textWrapperReversed p {
    text-align: center;
  }

  .imageWrapper img {
    width: 100%;
    max-width: 100%;
  }
 }
