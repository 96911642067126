.homeSection {
  height: 90vh;
  /* height: 100%; */
  display: flex;
  justify-content: center;
}

h1 {
  font-weight: 700;
  /* font-size: 4rem; */
  font-size: min(5vw, 4rem);
}

h2 {
  font-weight: 700;
  color: var(--color-white);
  margin-bottom: 4rem;
}

h3 {
  font-weight: 700;
  color: var(--color-white);
}

h5 {
  color: var(--color-white);
  font-weight: 600;
}

p {
  font-size: 1.1rem;
  font-weight: 500;
}

.textSide {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  row-gap: 5vh;
  padding-right: 5rem;
}

.imageSide {
  width: 55%;
  display: flex;
  align-items: center;
  text-align: right;
  /* margin-top: 5rem */
}

.imageWrapper img {
  max-width: 130%;
  border-radius: 2.5rem;
  outline: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.whyUsSection {
  background-color: var(--color-green);
  height: 100%;
  text-align: center;
  padding: 3rem 0;
  scroll-margin-top: 89px;
}

.colContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.colContent p {
  color: var(--color-white-light);
  font-weight: 400;
  font-size: 1rem;
}

.circleService {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  background-color: var(--color-white);
  border-radius: 100%;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

/* 
@media (min-width: 576px) { ... }

@media (min-width: 768px) { ... }

@media (min-width: 1200px) { ... }

@media (min-width: 1400px) { ... } */

@media (max-width: 992px) {
  .homeSection {
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    padding: 2rem 0;
    height: 100%;
  }

  .textSide {
    width: 100%;
    align-items: center;
    padding: 0;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .imageSide {
    width: 100%;
  }

  .imageWrapper img {
    max-width: 100%;
  }

  h1 {
    font-size: 2.5rem;
  }

  .colContent {
      gap: 1rem;
      margin-bottom: 2rem;
  }

  .colContent p {
      max-width: 30rem;
  }
}
