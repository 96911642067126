@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Epilogue", sans-serif;

  --font-family: "Epilogue", sans-serif;
  --color-white: #fff;
  --color-white-light: rgba(255, 255, 255, 0.836);

  --color-green: #25a762;
  --color-black: #111111;
  --color-green-hover: #269258;
  --color-navbar: rgba(255, 255, 255, 0.7);

  overflow-x: hidden;
}


.image-gallery-svg:hover {
  color: var(--color-white);
}


.image-gallery-thumbnail {
  background-color: transparent;
}

.image-gallery-fullscreen-button:hover {
  color: var(--color-white);
}

.image-gallery-thumbnail.active {
  outline: none;
  border: 4px solid var(--color-green);
}

.image-gallery-thumbnail:hover {
  border: 4px solid var(--color-green);
}

.image-gallery-svg {color: var(--color-white);}