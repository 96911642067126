.titleSection {
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.titleSection h1 {
    font-size: 2.5rem;
    color: var(--color-black);
}

.titleSection p {
    max-width: 30rem;
    text-align: center;
    margin: 0;
    padding: 0;
}

.contactInfoSection {
    height: 100%;
    background-color: var(--color-green);
    padding: 3rem 0 3rem;
    text-align: center;
}

.lightText {
    color: var(--color-white);
}

.mapStyle {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    margin-top: 20px;
}