.footer {
    background-color: var(--color-green);
    text-align: center;
    padding: 1rem 0;
    color: var(--color-white);
}

.footer p {
    font-size: 1rem;
    margin: 0;
    padding: 0;
}