.myButton {
    background-color: var(--color-green);
    border-radius: 2rem;
    padding: 0.7rem 2rem;
    border: none;
    font-weight: 600;
    max-width: 10rem;
    height: 3rem;
}

.myButton:hover {
    background-color:  var(--color-green-hover);
}

.myButton:active,
.myButton:focus {
  box-shadow: none !important;
  background-color: var(--color-green);
}


